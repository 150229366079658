@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Passion+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Press Start 2P', cursive;
}


.intro span {
  color: black;
}

.btnfos-0-2-2:active {
  background-color: #474747;
}

.btnfos-0-2:active {
  background-color: #474747;
}

.btnfos-0-3:active {
  background-color: #474747;
}


.page-loader {
  width: 100%;
  height: 100vh;
  background: #000000;
  z-index: 100000;
  position: fixed;
}

.loadingText2:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

.freemintLoading {
  font-size: 25px;
  margin-top: 3%;
  background: -webkit-linear-gradient(#1df739, #b3fe00, #b3fe00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: italic;

}

.maxAmount {
  color: white;
}

.freemintfade {
  display: none;
}

.page-loader .loadLogo {

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  top: 30%;
  position: relative;

  font-size: 40px;
  margin-top: 2%;
}

.loadLogo img {
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-animation: zoominoutsinglefeatured 3s infinite;
          animation: zoominoutsinglefeatured 3s infinite;
}

@media screen and (max-width: 499px) {

  .nftPic2 {
    width: 60%;
  }

  .walletImg {
    width: 30px;
    height: 30px;
    margin-top: -5px;
    margin-right: 20px;
  }

  .placeAndWL {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .mintingAmount {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    width: 200px;
    text-align: center;
    margin-bottom: 15px;
  }

  .btnfos-0-4 {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;

    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .btnfos-0-4:hover {
    background-color: #0056b3;
  }

  .conH {
    color: #ffffff;
    font-size: 30px;
    letter-spacing: 3px;
    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;

  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;

  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;

  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 14px;
    color: black;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;

    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: black;
    font-size: 125px;
    line-height: 1.2;
    margin-top: 6%;
    margin-bottom: 6%;
    font-family: 'Passion One', cursive;
  }

  .intro2 {
    color: black;
    font-size: 20px;
    text-align: center;


    line-height: 1.2;
  }

  .mintDiv {

    width: 95%;
    /* From https://css.glass */
    background: rgba(39, 39, 39, 0.301);

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    margin-top: -7%;
    padding-top: 70px;
    padding-bottom: 10px;
    background: url(/static/media/mintBg.95ebf60e.png);
    background-size: cover;
  }

  .errorMessage {

    font-size: 10px;
    color: #fc534d;
    text-align: center;
  }

  .errorMessageWL {

    font-size: 10px;
    color: #fc534d;
    text-align: center;
  }

  .loadingContainer {}

  .loadingText {
    font-size: 15px;
    text-align: center;
    color: gray;


  }

  .loadingTextWL {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  .loadingTextWL:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {

    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 55px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: #4b2e02;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0);
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;

  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;

  }







  .btnfos-0-3 {
    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;

    border: none;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    justify-content: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5%;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: 5%;

  }


  .btnfos-0-3:hover {

    color: gray;
    cursor: pointer;

  }



  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .loadingContainerWL {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: #00e522;
    font-size: 14px;

  }

  .successfullyWL {
    text-align: center;
    color: #00e522;
    font-size: 14px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #011590;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: gray 3px solid;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }

  .pageT {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    font-size: 20px;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center;

    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    font-weight: 500;
    color: white;
    background-color: #76a4d8;
    border: none;

    z-index: 100000000000;

    border: 4px solid #7c7c7c;
    border-top-color: #fff;
    border-left-color: #fff;
    background-color: #c0c0c0;
    box-shadow: 3px 3px black;
    color: black;
    margin-bottom: 10%;

  }



  .wallet3:hover {
    background-color: rgb(168, 168, 168);
  }

  .wallet3:active {
    border: 4px solid #7c7c7c;
    border-bottom-color: #fff;
    border-right-color: #fff;
    background-color: #c0c0c0;
    box-shadow: -3px -3px black;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {

    display: none;
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: url('https://pbs.twimg.com/media/D7PkEjaXkAAcIxU.jpg:large');
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .nftPic2 {
    width: 50%;
  }

  .walletImg {
    height: 30px;
    margin-top: -5px;
    margin-right: 20px;
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;

  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;

  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: black;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;

    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: black;
    font-size: 150px;
    margin-top: 6%;
    margin-bottom: 6%;
    line-height: 1.2;
    margin-left: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    font-family: 'Passion One', cursive;
  }

  .conH {
    color: #ffffff;
    font-size: 40px;
    letter-spacing: 3px;
    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;

  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .intro2 {
    color: black;
    font-size: 20px;
    text-align: center;


    line-height: 1.2;
  }

  .mintDiv {

    width: 95%;
    /* From https://css.glass */
    background: rgba(39, 39, 39, 0.301);

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    margin-top: -7%;
    padding-top: 70px;
    padding-bottom: 10px;
    background: url(/static/media/mintBg.95ebf60e.png);
    background-size: cover;
  }

  .errorMessage {

    font-size: 13px;
    color: #fc534d;
    text-align: center;


  }

  .errorMessageWL {

    font-size: 13px;
    color: #fc534d;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: gray;


  }

  .loadingTextWL {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;

  }

  .loadingTextWL:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;

  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    color: #6d3891b6;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {

    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 55px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: black 2px solid;

    background-color: rgba(255, 255, 255, 0);
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
  }


  .btnfos-0-3 {
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 12px;

    border: none;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #cbf3b0, #c0eb89);
    border-image-slice: 1;
    justify-content: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5%;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: 5%;

  }



  .btnfos-0-3:hover {

    color: gray;
    cursor: pointer;

  }



  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .loadingContainerWL {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: #00e522;
    font-size: 18px;

  }

  .successfullyWL {
    text-align: center;
    color: #00e522;
    font-size: 18px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #011590;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: gray 3px solid;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 18px;
    padding-left: 25px;
    padding-right: 25px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }

  .pageT {
    font-size: 22px;
    color: white;
    margin-bottom: 2%;
    display: flex;
  }

  .pageT div {
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 23px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;

    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    font-weight: 500;
    color: white;
    background-color: #76a4d8;
    border: none;

    z-index: 100000000000;
    border: 4px solid #7c7c7c;
    border-top-color: #fff;
    border-left-color: #fff;
    background-color: #c0c0c0;
    box-shadow: 3px 3px black;
    color: black;

    margin-bottom: 10%;

  }



  .wallet3:hover {
    background-color: rgb(168, 168, 168);
  }

  .wallet3:active {
    border: 4px solid #7c7c7c;
    border-bottom-color: #fff;
    border-right-color: #fff;
    background-color: #c0c0c0;
    box-shadow: -3px -3px black;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {

    display: none;
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: url('https://pbs.twimg.com/media/D7PkEjaXkAAcIxU.jpg:large');
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;


  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }


  .placeAndWL {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .mintingAmount {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    width: 200px;
  }

  .btnfos-0-4 {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;

    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }

  .btnfos-0-4:hover {
    background-color: #0056b3;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .nftPic2 {
    width: 20%;
  }

  .walletImg {
    width: 30px;
    height: 30px;
    margin-top: -2px;
    margin-right: 20px;
  }

  .icons {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 5%;
  }


  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;

  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 20px;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;

    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: black;
    font-size: 150px;
    margin-top: 6%;
    margin-bottom: 6%;
    font-family: 'Passion One', cursive;
    line-height: 1.2;
  }

  .conH {
    color: #ffffff;
    font-size: 50px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;

  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .intro2 {
    color: black;
    font-size: 20px;
    text-align: center;


    line-height: 1.2;
  }

  .mintDiv {

    width: 80%;
    /* From https://css.glass */
    background: rgba(39, 39, 39, 0.301);

    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    margin-top: -7%;
    padding-top: 70px;
    padding-bottom: 10px;
    background: url(/static/media/mintBg.95ebf60e.png);
    background-size: cover;
  }

  .errorMessage {

    font-size: 13px;
    color: #fc534d;
    text-align: center;


  }


  .errorMessageWL {

    font-size: 13px;
    color: #fc534d;
    text-align: center;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: gray;


  }

  .loadingTextWL {
    font-size: 16px;
    text-align: center;
    color: rgb(255, 255, 255);


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  .loadingTextWL:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }


  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    flex-flow: column nowrap;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    padding-top: 5px;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 80px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer;
    border: black 2px solid;

    background-color: rgba(255, 255, 255, 0);
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 60px;
    height: 60px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
  }








  .btnfos-0-3 {
    font-size: 27px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 12px;

    border: none;

    justify-content: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5%;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: 5%;

  }

  .btnfos-0-3:hover {

    color: gray;
    cursor: pointer;

  }



  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .loadingContainerWL {
    text-align: center;
  }

  .successfully {
    text-align: center;
    color: #00e522;
    font-size: 20px;

  }

  .successfullyWL {
    text-align: center;
    color: #00e522;
    font-size: 20px;

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #011590;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: gray 3px solid;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 21px;
    padding-left: 25px;
    padding-right: 25px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
  }

  .pageT {
    font-size: 22px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-bottom: 2%;
  }

  .pageT div {
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;

    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    font-weight: 500;
    color: white;
    background-color: #76a4d8;
    border: none;

    z-index: 100000000000;
    border: 4px solid #7c7c7c;
    border-top-color: #fff;
    border-left-color: #fff;
    background-color: #c0c0c0;
    box-shadow: 3px 3px black;
    color: black;

    margin-bottom: 10%;
  }



  .wallet3:hover {
    background-color: rgb(168, 168, 168);
  }

  .wallet3:active {
    border: 4px solid #7c7c7c;
    border-bottom-color: #fff;
    border-right-color: #fff;
    background-color: #c0c0c0;
    box-shadow: -3px -3px black;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {

    display: none;
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: url('https://pbs.twimg.com/media/D7PkEjaXkAAcIxU.jpg:large');
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 400px;
    /* Add the glow effect */


    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }


  .placeAndWL {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .mintingAmount {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    width: 200px;
  }

  .btnfos-0-4 {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;

    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }

  .btnfos-0-4:hover {
    background-color: #0056b3;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .nftPic2 {
    width: 15%;
    margin-bottom: 20px;
  }

  .walletImg {
    width: 30px;
    height: 30px;
    margin-top: -12px;
    margin-right: 20px;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 20px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;

  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 18px;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;

  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;

    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: black;
    font-size: 130px;
    margin-bottom: 10px;
    line-height: 1.2;
    font-family: 'Passion One', cursive;
  }

  .conH {
    color: #ffffff;
    font-size: 70px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;

  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 20px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }


  .intro2 {
    color: black;
    font-size: 18px;
    text-align: justify;


    line-height: 1.2;
  }

  .mintDiv {

    width: 45%;
    /* From https://css.glass */
    background: rgba(39, 39, 39, 0.301);


    padding-top: 60px;
    padding-bottom: 10px;

    background: url(/static/media/mintBg.95ebf60e.png);
    background-size: cover;
  }

  .errorMessage {

    font-size: 12px;
    color: #fc534d;
    text-align: center;


  }

  .errorMessageWL {

    font-size: 12px;
    color: #fc534d;
    text-align: left;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 16px;

    text-align: center;
    color: gray;

  }

  .loadingTextWL {
    font-size: 16px;

    text-align: left;
    color: rgb(255, 255, 255);

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  .loadingTextWL:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }


  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }


  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 10px;
    padding-left: 10px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 70px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    border: black 2px solid;

    background-color: rgba(255, 255, 255, 0);
  }

  .btnfos-0-2-2 {
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;

    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
  }








  .btnfos-0-3 {
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 12px;

    border: none;

    justify-content: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5%;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: 5%;

  }

  .btnfos-0-3:hover {

    color: gray;
    cursor: pointer;

  }



  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }


  .loadingContainerWL {
    text-align: left;
  }

  .successfully {
    text-align: center;
    color: #00e522;
    font-size: 18px;

  }

  .successfullyWL {
    text-align: left;
    color: #00e522;
    font-size: 18px;

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 10vh;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #011590;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: gray 3px solid;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-weight: 500;
    margin-left: 15px;
  }

  .pageT {
    font-size: 22px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;

    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    font-weight: 500;
    color: white;
    background-color: #76a4d8;
    border: none;

    z-index: 100000000000;

    border: 4px solid #7c7c7c;
    border-top-color: #fff;
    border-left-color: #fff;
    background-color: #c0c0c0;
    box-shadow: 3px 3px black;
    color: black;

  }



  .wallet3:hover {
    background-color: rgb(168, 168, 168);
  }

  .wallet3:active {
    border: 4px solid #7c7c7c;
    border-bottom-color: #fff;
    border-right-color: #fff;
    background-color: #c0c0c0;
    box-shadow: -3px -3px black;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: url('https://pbs.twimg.com/media/D7PkEjaXkAAcIxU.jpg:large');
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 350px;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }


  .placeAndWL {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;

  }

  .mintingAmount {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    width: 200px;
  }

  .btnfos-0-4 {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;

    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }

  .btnfos-0-4:hover {
    background-color: #0056b3;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .walletImg {
    width: 30px;
    height: 30px;
    margin-top: -12px;
    margin-right: 20px;
  }

  .cont {
    z-index: 10000000000;
  }

  .icons {
    display: flex;
  }

  .picAndChecked {
    display: flex;
    justify-content: center;
  }

  .picAndChecked img {
    width: 40px;
    height: 40px;
    margin-left: 5%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }

  .mintingAmount {
    font-size: 20px;
    padding: 10px;
  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;

  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 37px;
    height: 37px;
    cursor: pointer;

  }

  .osPic2 {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border-radius: 100px;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .discord2 {
    display: none;
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;

    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: black;
    font-size: 150px;
    font-family: 'Passion One', cursive;
    line-height: 1.2;
  }

  .conH {
    color: #ffffff;
    font-size: 70px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;

  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 20px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 18px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .intro2 {
    color: black;
    font-size: 20px;
    text-align: justify;


    line-height: 1.2;
  }

  .mintDiv {

    width: 50%;
    /* From https://css.glass */
    background: rgba(39, 39, 39, 0.301);
    background: url(/static/media/mintBg.95ebf60e.png);
    background-size: cover;
    margin-bottom: 20px;
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .errorMessage {

    font-size: 13px;
    color: #fc534d;
    text-align: center;


  }

  .errorMessageWL {

    font-size: 16px;
    color: #fc534d;
    text-align: left;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 16px;
    text-align: center;
    color: gray;


  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .loadingTextWL {
    font-size: 18px;
    text-align: left;
    color: rgb(255, 255, 255);
  }

  .loadingTextWL:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;

    justify-content: space-evenly;
    width: -webkit-max-content;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord {
    padding-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 70px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid black;
  }

  .btnfos-0-2-2 {
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
  }







  .btnfos-0-3 {
    font-size: 23px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 15px;

    justify-content: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5%;

    border: none;

    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: 5%;
  }

  .btnfos-0-3:hover {

    color: gray;
    cursor: pointer;

  }


  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .loadingContainerWL {
    text-align: left;
  }

  .successfully {
    text-align: center;
    color: #00e522;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;

  }

  .successfullyWL {
    text-align: left;
    color: #00e522;
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: left;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #011590;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: gray 3px solid;
    color: white;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    padding-left: 25px;
    padding-right: 25px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-weight: 500;
    margin-left: 15px;
  }

  .pageT {
    font-size: 23px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.2 ease-in;

  }

  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;

    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    font-weight: 500;
    color: white;
    background-color: black;
    border: none;
    background-color: #76a4d8;
    z-index: 100000000000;
    border: 4px solid #7c7c7c;
    border-top-color: #fff;
    border-left-color: #fff;
    background-color: #c0c0c0;
    box-shadow: 3px 3px black;
    color: black;
  }


  .wallet3:hover {
    background-color: rgb(168, 168, 168);
  }

  .wallet3:active {
    border: 4px solid #7c7c7c;
    border-bottom-color: #fff;
    border-right-color: #fff;
    background-color: #c0c0c0;
    box-shadow: -3px -3px black;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 70%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    width: 130px;
    height: 130px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: url('https://pbs.twimg.com/media/D7PkEjaXkAAcIxU.jpg:large');
    background-color: #888;
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 420px;
  }

  .nftPic2 {
    width: 10%;
    margin-bottom: 20px;
    margin-top: -20px;
  }


  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .placeAndWL {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .mintingAmount {
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
  }

  .btnfos-0-4 {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;

    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }

  .btnfos-0-4:hover {
    background-color: #0056b3;
  }

}

@media screen and (min-width: 1920px) {

  .nftPic2 {
    width: 10%;
    margin-bottom: 20px;
    margin-top: -20px;
  }

  .walletImg {
    width: 40px;
    height: 40px;
    margin-top: -13px;
    margin-right: 20px;
  }

  .conH {
    color: #ffffff;
    font-size: 100px;

    color: white;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 2%;
    margin-top: 2%;
  }

  .aboutMain2 p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 28px;
  }

  .termsSection p {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 28px;

  }

  .termsSection {
    margin-top: 3%;
  }

  .tP {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 30px;
    margin-top: 2%;
  }

  .termsSection div {
    color: white;
    font-family: 'Fira Sans', sans-serif;
    font-size: 28px;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .aboutMain3 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
    margin-bottom: 5%;
  }

  .aboutMain2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-flow: column;
  }

  .icons {
    display: flex;
  }

  .nftPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .discord4 {
    display: none;
  }

  /*.............. FAQ ..............*/

  summary {
    font-size: 30px;
    font-weight: 600;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;

    border: 2px solid #414141;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }

  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .faq__content {

    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 25px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 90px;
    color: white;
    text-shadow: 0 0 5px #ffffff;
  }

  .faqbg {
    width: 100%;
    text-align: left;
    padding: 2px;
    padding-bottom: 20px;
    margin-right: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 10%;
  }

  .writebtn {
    background-color: #53535385;
    color: white;
    padding: 10px;

    border: none;
  }


  .mintingAmount {
    font-size: 30px;
    padding: 10px;

  }

  .adH {
    font-size: 40px;
    color: white;
    text-align: center;

  }

  .boxWrap6 {
    margin-top: 5%;
  }

  .osPic {
    width: 55px;
    height: 55px;
    cursor: pointer;

  }

  .osPic2 {
    width: 90px;
    height: 90px;
    cursor: pointer;
    border-radius: 100px;
  }


  .price {
    text-align: center;
    font-size: 30px;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .discord {
    padding-right: 15px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .discord3 {
    padding-right: 20px;
    display: none;
  }


  .uRMobile {
    display: none;
  }

  .storyPicDivMobile {
    display: none;
  }

  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }

  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #0000003f;
    padding-top: 5%;
    padding-bottom: 5%;

    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 10%;
    margin-bottom: 10%;
  }

  .storyPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .storyH {
    font-size: 50px;

    text-shadow: 4px 3px 0 #383d6e62;
    color: white;
    margin-bottom: 10px;
    margin-top: 10%;
  }

  .storyCon {
    color: white;
    font-size: 22px;
    text-shadow: 0 0 10px #ffffff4b;
  }

  .storyPic {
    width: 200px;
    height: 250px;
    border: 2px solid #d423b7;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 10px #d64dbf;
  }

  .storyRight {
    width: 90%;
    text-align: center;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
  }

  .uR {
    width: 50%;
  }

  .intro {
    color: black;
    font-size: 190px;
    margin-bottom: 5px;
    line-height: 1.2;
    font-family: 'Passion One', cursive;
  }

  .intro2 {
    color: black;
    font-size: 35px;
    text-align: justify;


    line-height: 1.2;
  }

  .mintDiv {

    width: 40%;
    /* From https://css.glass */
    background: rgba(39, 39, 39, 0.301);
    background: url(/static/media/mintBg.95ebf60e.png);
    background-size: cover;

    padding-top: 90px;
    padding-bottom: 20px;
  }

  .errorMessage {

    font-size: 20px;
    color: #fc534d;
    text-align: center;


  }

  .errorMessageWL {

    font-size: 23px;
    color: #fc534d;
    text-align: left;


  }

  .loadingContainer {}

  .loadingText {
    font-size: 22px;
    text-align: center;
    color: gray;

  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 13px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .loadingTextWL:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  .loadingTextWL {
    font-size: 25px;
    text-align: left;
    color: rgb(255, 255, 255);

  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    width: -webkit-max-content;
    width: max-content;
    margin-top: auto;
    margin-bottom: auto;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }

  .discord2 {
    padding-right: 20px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    margin-top: auto;
    margin-bottom: auto;
  }

  .discord2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }


  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;

  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 5px;

  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;


  }

  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;

  }


  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }


  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: -webkit-max-content;
    height: max-content;
  }

  .nftminter2 {
    color: black;
    font-size: 110px;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    justify-content: center;
  }

  .nftminter {
    background-color: #18181a;
    justify-content: flex-start;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 2px solid;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 30px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 75px;
    height: 75px;
    border: none;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0);
    border: 4px solid black;
  }

  .btnfos-0-2-2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    font-size: 30px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 10px;
    width: 75px;
    height: 75px;
    border: none;
    cursor: pointer;
    border: none;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 4px solid;
  }








  .btnfos-0-3 {
    font-size: 35px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;

    border: none;

    justify-content: center;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 5%;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    border: black 4px solid;
    margin-top: 5%;
    padding: 20px;
  }

  .btnfos-0-3:hover {

    color: gray;
    cursor: pointer;

  }



  .nftamount {
    color: rgb(255, 174, 0);
    font-size: 120px;
    text-shadow: 4px 3px 0 #383d6e17;
  }

  .walletConnect {
    display: flex;
    margin-top: 2%;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
  }

  .loadingContainerWL {
    text-align: left;
  }

  .successfully {
    text-align: center;
    color: #00e522;
    font-size: 23px;

  }

  .successfullyWL {
    text-align: left;
    color: #00e522;
    font-size: 23px;

  }


  .headers {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }

  .headers2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 90%;

    margin-left: auto;
    margin-right: auto;


    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

  }


  .introduction {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .wallet2 {
    background-color: #011590;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: gray 5px solid;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    padding-left: 25px;
    padding-right: 25px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    font-weight: 500;
    margin-left: 15px;
  }

  .pageT {
    font-size: 35px;
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
  }

  .pageT div {
    margin-left: 15px;
    margin-right: 15px;
    cursor: pointer;
    transition: 0.2 ease-in;
  }

  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }

  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }


  .wallet3 {

    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 35px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;

    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    font-weight: 500;
    color: white;
    background-color: #76a4d8;
    border: none;

    z-index: 100000000000;
    border: 6px solid #7c7c7c;
    border-top-color: #fff;
    border-left-color: #fff;
    background-color: #c0c0c0;
    box-shadow: 5px 5px black;
    color: black;
  }



  .wallet3:hover {
    background-color: rgb(168, 168, 168);
  }

  .wallet3:active {
    border: 6px solid #7c7c7c;
    border-bottom-color: #fff;
    border-right-color: #fff;
    background-color: #c0c0c0;
    box-shadow: -5px -5px black;
  }

  @-webkit-keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .in2 {
    width: 50%;
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
  }

  .totalSupply {
    text-align: center;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: black;
    text-shadow: 4px 3px 0 #383d6e3a;

  }

  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }

  }

  .logoPic {
    width: 170px;
    height: 170px;
  }

  .logo {
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .footer {
    background-color: rgba(0, 0, 0, 0);
    padding: 2%;
    position: static;
    margin-top: 10%;
  }

  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 50px;
    text-decoration: underline;
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .light2 {
    height: 200vh;
  }

  .light {
    background: url('https://pbs.twimg.com/media/D7PkEjaXkAAcIxU.jpg:large');
    background-size: cover;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
  }

  @keyframes shake {

    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }

    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @-webkit-keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }

    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }

  }

  @-webkit-keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  .nftPic {
    width: 600px;
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }

    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }

    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }

  /* CSS */
  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #ffffff;
    display: inline-block;
    font-size: 70px;
    line-height: 15px;
    padding: 50px 50px 50px;
    text-decoration: none;
    cursor: pointer;
    background: rgba(240, 97, 221, 0.171);
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;

  }

  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }

  .button-57 span:last-child {
    color: white;
    display: flex;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(225%) translateX(-50%);
            transform: translateY(225%) translateX(-50%);
    height: 30px;
    line-height: 30px;
  }

  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    transition: -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1), -webkit-transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    -webkit-transform: skewY(9.3deg) scaleY(0);
            transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }

  .button-57:hover:after {
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
    -webkit-transform: skewY(9.3deg) scaleY(2);
            transform: skewY(9.3deg) scaleY(2);
  }

  .button-57:hover span:last-child {
    -webkit-transform: translateX(-50%) translateY(-100%);
            transform: translateX(-50%) translateY(-100%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }


  .placeAndWL {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .mintingAmount {
    padding: 16px;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    outline: none;
  }

  .btnfos-0-4 {
    padding: 15px 25px;
    border: none;
    border-radius: 5px;
    font-size: 22px;

    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
  }

  .btnfos-0-4:hover {
    background-color: #0056b3;
  }
}
@media screen and (max-width: 499px) {
  
  
  .cs{
    font-family: 'Passion One', cursive;
    margin-top: 20px;
    -webkit-text-stroke: #000000 2px;
    color: white;
    font-size: 30px;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }

  .dots{
    color: gray;
    display: flex;
    align-items: center;
  }
  
  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;        
      }
    
      .countdown{
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 20px;
       }
    
    .days{
        border: 6px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        background-color: #c0c0c0;
        box-shadow: 5px 5px black;
        padding: 10px;
         
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        -webkit-animation: 1s 0.875s infinite timer_beat;
                animation: 1s 0.875s infinite timer_beat;
        border: 6px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        background-color: #c0c0c0c9;
        box-shadow: 5px 5px black;
        padding: 10px;
       
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @-webkit-keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
      
    @keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
   
  .cs{
    font-family: 'Passion One', cursive;
    margin-top: 20px;
    -webkit-text-stroke: #000000 2px;
    color: white;
    font-size: 40px;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }
 
  .dots{
    color: gray;
    display: flex;
    align-items: center;
  }
  
  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 25px;
        margin-bottom: 20%;

      }
    
      .days{
       
        border: 6px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        background-color: #c0c0c0;
        box-shadow: 5px 5px black;
        width: 100px;
        height: 100px;
        padding-top: 25px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
        color: black;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-right: 5px;
          margin-left: 5px;
  
      }
      .sec{
          -webkit-animation: 1s 0.875s infinite timer_beat;
                  animation: 1s 0.875s infinite timer_beat;
         
          border: 6px solid #7c7c7c;
          border-top-color: #fff;
          border-left-color: #fff;
          background-color: #c0c0c0c9;
          box-shadow: 5px 5px black;
          width: 100px;
          height: 100px;
          padding-top: 25px;
          padding-bottom: 20px;
          padding-right: 20px;
          padding-left: 20px;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-left: 5px;
      
      }
      
    @-webkit-keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
      
    @keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
      
  .cs{
    font-family: 'Passion One', cursive;
    margin-top: 20px;
    -webkit-text-stroke: #000000 2px;
    color: white;
    font-size: 50px;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }
  
  .dots{
    color: gray;
    display: flex;
    align-items: center;
  }
  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 35px;
       }
    
      .days{
       
        border: 6px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        background-color: #c0c0c0;
        box-shadow: 5px 5px black;
        width: 150px;
        height: 150px;
        padding-top: 50px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 25px;
        color: black;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-right: 5px;
          margin-left: 5px;
  
      }
      .sec{
          -webkit-animation: 1s 0.875s infinite timer_beat;
                  animation: 1s 0.875s infinite timer_beat;
         
          border: 6px solid #7c7c7c;
          border-top-color: #fff;
          border-left-color: #fff;
          background-color: #c0c0c0c9;
          box-shadow: 5px 5px black;
          width: 150px;
          height: 150px;
          padding-top: 50px;
          padding-bottom: 20px;
          padding-right: 20px;
          padding-left: 25px;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-left: 5px;
      
      }
      
    @-webkit-keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
      
    @keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
 
   
  .cs{
    font-family: 'Passion One', cursive;
    margin-top: 20px;
    -webkit-text-stroke: #000000 2px;
    color: white;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }
  .dots{
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    
      .countdown{
        text-align: center;
        color: black;
        font-size: 60px;
      }

      
      .dots{
        color: gray;
        display: flex;
        align-items: center;
      }
    
      .days{
       
        border: 6px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        background-color: #c0c0c0;
        box-shadow: 5px 5px black;
        width: 170px;
        height: 170px;
        padding-top: 40px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 25px;
        color: black;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-right: 5px;
          margin-left: 5px;
  
      }
      .sec{
          -webkit-animation: 1s 0.875s infinite timer_beat;
                  animation: 1s 0.875s infinite timer_beat;
         
        border: 6px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        background-color: #c0c0c0c9;
        box-shadow: 5px 5px black;
        width: 170px;
        height: 170px;
        padding-top: 40px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 25px;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-left: 5px;
      
      }
      
    @-webkit-keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
      
    @keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .cs{
    font-family: 'Passion One', cursive;
    margin-top: 20px;
    -webkit-text-stroke: #000000 2px;
    color: white;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }

  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    
      .countdown{
        text-align: center;
        color: black;
        font-size: 60px;     
       }
      
      .dots{
        color: gray;
        display: flex;
        align-items: center;
      }
    
    .days{
     
      border: 6px solid #7c7c7c;
      border-top-color: #fff;
      border-left-color: #fff;
      background-color: #c0c0c0;
      box-shadow: 5px 5px black;
      width: 170px;
      height: 170px;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-right: 20px;
      padding-left: 25px;
      color: black;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;

    }
    .sec{
        -webkit-animation: 1s 0.875s infinite timer_beat;
                animation: 1s 0.875s infinite timer_beat;
       
      border: 6px solid #7c7c7c;
      border-top-color: #fff;
      border-left-color: #fff;
      background-color: #c0c0c0c9;
      box-shadow: 5px 5px black;
      width: 170px;
      height: 170px;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-right: 20px;
      padding-left: 25px;
        
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @-webkit-keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
      
    @keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
}

@media screen and (min-width: 1920px) {
  
  .cs{
    font-family: 'Passion One', cursive;
    margin-top: 20px;
    -webkit-text-stroke: #000000 2px;
    color: white;
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2), 0px -5px 35px rgba(255,255,255,0.3);
  }

  .dots{
    color: gray;
    display: flex;
    align-items: center;
  }

  .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        text-align: center;
        color: black;
        font-size: 80px;

      }
    
      .days{
       
        border: 8px solid #7c7c7c;
        border-top-color: #fff;
        border-left-color: #fff;
        background-color: #c0c0c0;
        box-shadow: 7px 7px black;
        width: 250px;
        height: 250px;
        padding-top: 67px;
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 25px;
        color: black;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-right: 5px;
          margin-left: 5px;
  
      }
      .sec{
          -webkit-animation: 1s 0.875s infinite timer_beat;
                  animation: 1s 0.875s infinite timer_beat;
         
          border: 8px solid #7c7c7c;
          border-top-color: #fff;
          border-left-color: #fff;
          background-color: #c0c0c0c9;
          box-shadow: 7px 7px black;
          width: 250px;
          height: 250px;
          padding-top: 67px;
          padding-bottom: 20px;
          padding-right: 20px;
          padding-left: 25px;
          
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          margin-left: 5px;
      
      }
      
    @-webkit-keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
      
    @keyframes timer_beat {
      0% {
        -webkit-transform: scale(1.125);
                transform: scale(1.125); 
      }
      50% {
        -webkit-transform: none;
                transform: none; }
       }
}
